import React from 'react';

const Features = () => {
  return (
    <section id='features'>
      {/* Flex Container */}
      <div className='container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row'>
        {/* What's Different */}
        <div className='flex flex-col space-y-12 md:w-1/2'>
          <h2 className='max-w-md text-4xl font-bold text-center md:text-left'>
            Qual Nosso diferencial ?
          </h2>
          <p className='max-w-sm text-center text-darkGrayishBlue md:text-left'>
            Uma plataforma completa, 100% gerenciável e transparente, basta passar os detalhes para nossos técnicos, a instalação e feita e 
            pronto ! somente uma integração é instalada e seu servidor está pronto para monetizar !
          </p>
        </div>

        {/* Numbered List */}
        <div className='flex flex-col space-y-8 md:w-1/2'>
          {/* List Item 1 */}
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-brightRedSupLight md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1 bg-red-800'>
                  01
                </div>
                <h3 className='text-base font-bold md:mb-4 md:hidden'>
                  Acompanhe seus negócios
                </h3>
              </div>
            </div>

            <div>
              <h3 className='hidden mb-4 text-lg font-bold md:block'>
              Acompanhe seus negócios
              </h3>
              <p className='text-darkGrayishBlue'>
                Em tempo real, gerencie o conteúdo do seu game, notícias, financeiro, e-commerce e muito mais...
              </p>
            </div>
          </div>

          {/* List Item 2 */}
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-brightRedSupLight md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1  bg-red-800'>
                  02
                </div>
                <h3 className='text-base font-bold md:mb-4 md:hidden'>
                  Servidor
                </h3>
              </div>
            </div>

            <div>
              <h3 className='hidden mb-4 text-lg font-bold md:block'>
              Servidor
              </h3>
              <p className='text-darkGrayishBlue'>
                Com nossa estratégica de integração, o custo é ainda menor para manter sua plataforma, 
                basta rodar nossa integração e o restante a equipe Game CMS faz sozinha ! Rápido e Fácil.
              </p>
            </div>
          </div>

          {/* List Item 3 */}
          <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
            {/* Heading */}
            <div className='rounded-l-full bg-brightRedSupLight md:bg-transparent'>
              <div className='flex items-center space-x-2'>
                <div className='px-4 py-2 text-white rounded-full md:py-1  bg-red-800'>
                  03
                </div>
                <h3 className='text-base font-bold md:mb-4 md:hidden'>
                  Financeiro
                </h3>
              </div>
            </div>

            <div>
              <h3 className='hidden mb-4 text-lg font-bold md:block'>
              Financeiro
              </h3>
              <p className='text-darkGrayishBlue'>
                Tudo que você precisa em um só lugar, controle os ganhos do servidor e as despesas à pagar todo mês.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
