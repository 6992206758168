import { Link } from 'react-router-dom';

import companyLogoWhite from '../assets/images/logo.png';

const Footer = () => {
  return (
    <div className='bg-veryDarkBlue'>
      {/* Flex Container */}
      <div className='container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 md:flex-row md:space-y-0'>
        {/* Logo and social links container */}
        <div className='flex flex-col-reverse items-center justify-between space-y-12 md:flex-col md:space-y-0 md:items-start'>
          <div className='mx-auto my-6 text-center text-white md:hidden'>
            Game CMS Open GNU License
          </div>
          {/* Logo */}
          <div>
            <img src={companyLogoWhite} className='h-24' alt='' />
          </div>
          
        </div>
        {/* List Container */}
        <div className='flex justify-around space-x-32'>
          <div className='flex flex-col space-y-3 text-white'>
            <a href='#' className='hover:text-brightRed'>
              Inicio
            </a>
            <a href='#features' className='hover:text-brightRed'>
              Nosso Produto
            </a>
            <a href='#testimonials' className='hover:text-brightRed'>
              Parceiros
            </a>
          </div>
          <div className='flex flex-col space-y-3 text-white'>
            <a href='https://encyclopedia.kaspersky.com/glossary/closed-source/#:~:text=Closed%2Dsource%20software%20(proprietary%20software)%20is%20software%20whose%20author,categorized%20as%20closed%2Dsource%20software.' target='_blank' className='hover:text-brightRed'>
              Licenciamento
            </a>
            <a href='https://discord.gg/pyzyTBbvn2' target='_blank' className='hover:text-brightRed'>
              Discord
            </a>
            <a href='tel:351926535637' target='_blank' className='hover:text-brightRed'>
              Contacto: + (351)  926 525 637
            </a>
          </div>






        </div>

        {/* Input Container */}
        <div className='flex flex-col justify-between'>
          
          <div className='hidden text-white md:block'>
            <strong>Endereço:</strong> <br/>Antonio Vieira de Castro 637, 4490071 Povoa de Varzim, Porto, Portugal.<br/>
            © Copyright 2019-2024 - Game CMS All Rights Reserved.
          </div>

        </div>
      </div>
    </div>
  );
};

export default Footer;
