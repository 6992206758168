import { useState } from 'react';
import { Link } from 'react-router-dom';

import companyLogo from '../assets/images/logo.png';
import Swal from "sweetalert2";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const openBlackListModal = () => {
    Swal.fire({
      title: "Integre conosco ! É gratuíto e sempre será.",
      html: "<BR><label class='float-left'><strong>Backend (Para sua API de cadastro ou Firewall):</strong> <strong style='color: brown;'>/GET</strong> https://back.gamecms.com.br/api/v1/shareddata/blacklist</label>" +
          "<a href='https://back.gamecms.com.br/api/v1/shareddata/blacklist' target='_blank'>" +
          "<button  class='float-right swal2-confirm swal2-styled'>Ver no Browser</button></a>" +
          "<BR><BR><strong class='float-left'>Exemplo PHP:</strong><BR>" +
          "<textarea style='background-color: black; width: 100%; color: white; padding: 16px; height: 240px;' disabled>" +

          "<?php\n" +
          "\n" +
          "// Obtenha o IP do usuário\n" +
          "$userIp = $_SERVER['REMOTE_ADDR'];\n" +
          "\n" +
          "$url = 'https://back.gamecms.com.br/api/v1/shareddata/blacklist';\n" +
          "$response = file_get_contents($url);\n" +
          "\n" +
          "if ($response === false) {\n" +
          "    // Tratamento de erro ao fazer a requisição\n" +
          "    die('Erro ao fazer a requisição.');\n" +
          "}\n" +
          "\n" +
          "// Decodifica o JSON para um array PHP\n" +
          "$data = json_decode($response, true);\n" +
          "\n" +
          "if ($data === null) {\n" +
          "    // Tratamento de erro ao decodificar o JSON\n" +
          "    die('Erro ao decodificar o JSON.');\n" +
          "}\n" +
          "\n" +
          "// Verifica se o IP do usuário está presente na lista\n" +
          "foreach ($data as $item) {\n" +
          "    if ($item['ip'] === $userIp) {\n" +
          "        // Se o IP do usuário estiver na lista, encerre a execução\n" +
          "        die('Seu IP está na lista negra.');\n" +
          "    }\n" +
          "}\n" +
          "\n" +
          "// Se o IP do usuário não estiver na lista, continue a execução\n" +
          "echo 'Seu IP não está na lista negra. Continue com a execução do script.';\n" +
          "?>\n"+
          "</textarea><BR><BR><strong class='float-left'>Retorno:</strong>" +
          "<textarea style='background-color: black; width: 100%; color: white; padding: 16px; height: 80px;'>[{\"id\":2,\"ip\":\"0:0:0:0:0:0:0:1\"}]</textarea>"+
          "<BR><BR>" +
          "<hr/><BR><BR><strong class='float-left'>Javascript (FrontEnd):</strong>"+
          "<textarea style='background-color: black; width: 100%; color: white; padding: 16px; height: 160px;'>const apiUrlGetIP = 'https://back.gamecms.com.br/api/v1/shareddata/getMyIp';\n" +
          "const apiUrlBlacklist = 'https://back.gamecms.com.br/api/v1/shareddata/blacklist';\n" +
          "\n" +
          "async function obterEnderecoIP() {\n" +
          "    try {\n" +
          "        const resposta = await fetch(apiUrlGetIP);\n" +
          "        \n" +
          "        if (resposta.ok) {\n" +
          "            const dados = await resposta.json();\n" +
          "            return dados.ip;\n" +
          "        } else {\n" +
          "            console.error(`Erro na solicitação: ${resposta.status} - ${resposta.statusText}`);\n" +
          "            return null;\n" +
          "        }\n" +
          "    } catch (erro) {\n" +
          "        console.error('Erro ao obter o endereço IP:', erro.message);\n" +
          "        return null;\n" +
          "    }\n" +
          "}\n" +
          "\n" +
          "async function verificarBlacklist(enderecoIP) {\n" +
          "    try {\n" +
          "        const resposta = await fetch(apiUrlBlacklist);\n" +
          "        \n" +
          "        if (resposta.ok) {\n" +
          "            const blacklist = await resposta.json();\n" +
          "            return blacklist.some(item => item.ip === enderecoIP);\n" +
          "        } else {\n" +
          "            console.error(`Erro na solicitação da blacklist: ${resposta.status} - ${resposta.statusText}`);\n" +
          "            return false;\n" +
          "        }\n" +
          "    } catch (erro) {\n" +
          "        console.error('Erro ao obter a blacklist:', erro.message);\n" +
          "        return false;\n" +
          "    }\n" +
          "}\n" +
          "\n" +
          "// Exemplo de uso\n" +
          "async function main() {\n" +
          "    const enderecoIP = await obterEnderecoIP();\n" +
          "    \n" +
          "    if (enderecoIP) {\n" +
          "        const estaNaBlacklist = await verificarBlacklist(enderecoIP);\n" +
          "\n" +
          "        if (estaNaBlacklist) {\n" +
          "            // Se estiver na blacklist, realiza a ação desejada\n" +
          "            document.innerHTML = '';\n" +
          "        } else {\n" +
          "            // Se não estiver na blacklist, continua com outras ações\n" +
          "            console.log('O endereço IP não está na blacklist.');\n" +
          "        }\n" +
          "    } else {\n" +
          "        console.log('Não foi possível obter o endereço IP.');\n" +
          "    }\n" +
          "}\n" +
          "\n" +
          "// Chama a função principal\n" +
          "main();\n</textarea>"+
          "<BR><BR>"
      ,
      showCloseButton: true,
      showConfirmButton: false,
      customClass: {
        container: 'custom-sw-container-class',
      },
      width: '80%',  // Ajuste a largura conforme necessário
    });
  };



  return (
    <nav className='relative container mx-auto p-6'>
      {/* Flex Container */}
      <div className='flex items-center justify-between'>
        {/* Logo */}
        <div className='pt-2'>
          <img src={companyLogo} alt='' width="120"/>
        </div>
        {/* Menu Items */}
        <div className='hidden space-x-6 md:flex'>
          <a href="#" className="hover:text-darkGrayishBlue">
            Inicio
          </a>

          <a href="#features" className="hover:text-darkGrayishBlue">
            Nosso Produto
          </a>
          <a href="#testimonials" className="hover:text-darkGrayishBlue">
            Parceiros
          </a>
          <a onClick={() => openBlackListModal()} className='bg-red-800 text-white w-36 rounded-lg text-center'>
              Black List Pública
          </a>
        </div>
      
        
        <a href="https://customer.gamecms.com.br" target='_blank' className='hidden p-3 px-6 pt-2 text-white text-xl bg-red-800 rounded-full baseline hover:bg-brightRedLight md:block'>
          Entrar
        </a>
       

        {/* Hamburger Icon */}
        <button
          className={
            toggleMenu
              ? 'open block hamburger md:hidden focus:outline-none'
              : 'block hamburger md:hidden focus:outline-none'
          }
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <span className='hamburger-top'></span>
          <span className='hamburger-middle'></span>
          <span className='hamburger-bottom'></span>
        </button>
      </div>

      {/* Mobile Menu */}
      <div className='md:hidden'>
        <div
          className={
            toggleMenu
              ? 'absolute flex flex-col items-center self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md'
              : 'absolute flex-col items-center hidden self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md'
          }
        >
          <a href="#">
            Inicio
          </a>

          <a href="#features" >
            Nosso Produto
          </a>
          <a href="#testimonials">
            Parceiros
          </a>
          <a onClick={() => openBlackListModal()} className='bg-red-800 text-white w-36 rounded-lg text-center'>
            Black List Pública
          </a>
          <a href='#' className='bg-red-800 p-4 text-white w-full text-center' href="https://customer.gamecms.com.br" >Entrar</a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
