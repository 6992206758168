import { Link } from 'react-router-dom';

import avatarLegacy from '../assets/images/legacy.png';
import avatarPower from '../assets/images/power.jpg';
import avatarAsteria from '../assets/images/asteria.webp';

const Testimonial = () => {
  return (
    <section id='testimonials'>
      {/* Container to heading and testm blocks */}
      <div className='max-w-6xl px-5 mx-auto mt-32 text-center'>
        {/* Heading */}
        <h2 className='text-4xl font-bold text-center'>
          Ganhe Conosco ! Assine agora e monetize seu jogo em poucos cliques
        </h2>
        {/* Testimonials Container */}
        <div className='flex flex-col mt-24 md:flex-row md:space-x-6'>
          {/* Testimonial 1 */}
          <div className='flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:w-1/3'>
            <img src={avatarLegacy} className='w-16 -mt-14 rounded-full' alt='' />
            <h5 className='text-lg font-bold'>Legacy Of Wars</h5>
            <p className='text-sm text-darkGrayishBlue'>
              “Há anos, trabalho com servidores de jogos, e sempre procuro a GameCMS pela transparência nos negócios.”
            </p>
          </div>

          {/* Testimonial 2 */}
          <div className='hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3'>
            <img src={avatarPower} className='w-16 -mt-14 rounded-full' alt='' />
            <h5 className='text-lg font-bold'>Power WYD</h5>
            <p className='text-sm text-darkGrayishBlue'>
              “Chega de comprar sites pela metade, a plataforma oferece todo o necessário para se monetizar qualquer tipo de jogo, recomendo muito.”
            </p>
          </div>

          {/* Testimonial 3 */}
          <div className='hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3'>
            <img src={avatarAsteria} className='w-16 -mt-14 rounded-full' alt='' />
            <h5 className='text-lg font-bold'>Asteria Mu</h5>
            <p className='text-sm text-darkGrayishBlue'>
              “Compramos diversas soluções com a Game CMS e não nos arrependemos, sempre disponíveis e muito dedicados.”
            </p>
          </div>
        </div>
        {/* Button */}
        <div className='my-16'>
          <a
            href='https://wa.me/351926525637'
            className='p-3 px-6 pt-2 text-white bg-red-800 rounded-full baseline hover:bg-brightRedLight'
          >
            Adquira Agora !
          </a>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
